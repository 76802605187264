<template>
  <div class="container">
     <search-item @input="searchlist"></search-item>
    <div class="block block1">
      <div class="left">
        <swiper
          :options="swiperOption"
          class="swiper-container swiper1"
          ref="mySwiper"
        >
          <swiper-slide
            v-for="(item, i) in banners"
            :key="i"
            class="swiper-item"
           
          >
            <!-- bind_id: 1
          bind_type: product news -->

            <img class="swiper-img" :src="item.image" alt="暂无图片"   @click="jumpdetail(item.bind_id, item.bind_type)" />
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
      <div class="right">
        <div class="up">
          <div class="welcome">
            <img src="@img/logo-s.png" alt="" />
            <div>Hi，欢迎来到日安管家</div>
          </div>
          <div class="btns flex justify-evenly" v-if="isLogin == 2">
            <div class="btn btn-info-sm" @click="registDialog">注册</div>
            <div class="btn btn-primary-sm" @click="loginDialog">登录</div>
          </div>
        </div>
        <div class="blockstyle down">
          <div class="toptitle">
            <img src="@img/kjrk.png" alt="" class="icon" />
            <span>快捷入口</span>
          </div>
          <div class="main">
            <img src="@img/fbxq.png" alt="" @click="openpage('fbxq', '', true)" />
            <img src="@img/lxgj.png" alt="" @click="getkefu()" />
            <img
              src="@img/grjm.jpg"
              alt=""
              @click="openpage('geren', '', true)"
            />
            <img
              src="@img/qyjm.jpg"
              alt=""
              @click="openpage('qiye', '', true)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="block">
      <div class="blockstyle">
        <div class="toptitle">
          <img src="@img/fwlc.png" alt="" class="icon" />
          <span>服务流程</span>
        </div>
        <div class="main fwlc">
          <img :src="lcimg" alt="" />
        </div>
      </div>
    </div>
    <div class="block">
      <div class="blockstyle">
        <div class="toptitle">
          <img src="@img/sgal.png" alt="" class="icon" />
          <span>服务案例</span>
          <div class="more" @click="openpage('sgal')">
            查看更多 <img src="@img/more.png" alt="" />
          </div>
        </div>
        <div class="main sgal">
          <swiper
            :options="swiperOption2"
            class="swiper-container"
            ref="mySwiper2"
          >
            <swiper-slide
              v-for="(item, i) in sgal"
              :key="i"
              class="swiper-item img2"
            >
              <div class="before" @click="openpage('/fwdetail',item.product_id)">
                <img class="swiper-img" :src="item.image" alt="暂无图片" />
              </div>
              <!--   <div class="before">
                <img class="swiper-img" :src="item.image" alt="暂无图片" />
                <div class="tip">施工前</div>
              </div>
              <div class="after">
                <img class="swiper-img" :src="item.image" alt="暂无图片" />
                <div class="tip">施工后</div>
              </div> -->
            </swiper-slide>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
          <div class="flexcenter zixun">
            <div class="btn btn-primary-m" @click="getkefu()">咨询管家</div>
          </div>
        </div>
      </div>
    </div>
    <div class="block">
      <div class="blockstyle">
        <div class="toptitle">
          <img src="@img/fwtj.png" alt="" class="icon" />
          <span>服务推荐</span>
          <div class="more" @click="openpage('fwtj')">
            查看更多 <img src="@img/more.png" alt="" />
          </div>
        </div>
        <div class="main fwtj">
          <div class="item" v-for="(item, i) in fwtj" :key="item.id">
            <div class="pic" @click="openpage('/fwdetail',item.product_id)">
              <img :src="item.image" alt="" v-if="item.image" />
            </div>
            <div class="txt">
              <span @click="openpage('/fwdetail',item.id)">{{ item.name }}</span>
              <!-- <div class="btn btn-primary-sm" @click="getkefu()">咨询管家</div> -->
            </div>
          </div>
        </div>
        <div class="flexcenter zixun">
            <div class="btn btn-primary-m" @click="getkefu()">咨询管家</div>
          </div>
      </div>
    </div>
    <div class="block">
      <div class="blockstyle mr10">
        <div class="toptitle">
          <img src="@img/wxkb.png" alt="" class="icon" />
          <span>维修快报</span>
          <!-- <div class="more">查看更多 <img src="@img/more.png" alt="" /></div> -->
        </div>
        <div class="main wxkb">
          <vue-seamless-scroll :data="listData" :class-option="classOption">
            <ul class="item">
              <li v-for="(item, index) in listData" :key="index" class="warp">
                <div class="kehu" v-text="item.name"></div>
                <div class="content" v-text="item.desc"></div>
                <div class="date" v-text="item.rtime_text"></div>
              </li>
            </ul>
          </vue-seamless-scroll>
        </div>
      </div>
      <div class="blockstyle ml10">
        <div class="toptitle">
          <img src="@img/zxhd.png" alt="" class="icon" />
          <span>资讯活动</span>
          <div class="more" @click="openpage('zxhd')">
            查看更多 <img src="@img/more.png" alt="" />
          </div>
        </div>
        <div class="main zxhd">
          <div
            class="item"
            v-for="(item, index) in zxhd"
            :key="item.id"
            @click="openpage('/zxdetail', item.id)"
          >
            <div class="left">
              <img :src="item.thumb" alt="" />
            </div>
            <div class="right">
              <div class="title">
                {{ item.title }}
              </div>
              <div class="desc">
                  {{ item.desc }}
              </div>
              <div class="bottom">
                <div class="see">{{ item.pv }}人看过</div>
                <div class="date">{{ item.rtime }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import searchItem from "@c/common/searchitem.vue";
import { mapState, mapMutations } from "vuex";
import vueSeamlessScroll from "vue-seamless-scroll";
var defaultbanner1 = require("@img/linshi/1.png");
var defaultbanner2 = require("@img/linshi/6.png");
export default {

  data() {
    return {
      classOption: {
        step: 0.5,
      },
      lcimg: "",
      listData: [
        /*  {
        kehu:"自动化技术服务商",
        content:"解决了客户写字楼整体空调设备 换代需求",
        date:"3分钟前"
      } */
      ],
      banners: [],
      sgal: [
        //   image: "http://rianfw.dev.zhangxinkeji.com/uploads/20220517/8b55e35f720ca2d4320bdcdf4bd9597d.png"
        // product_id: "1"
        // title: "11"
      ],
      zxhd: [],
      swiperOption: {
        loop: false,
        speed: 1000,
        slidesPerView: 1,
        spaceBetween: 20,
        centeredSlides: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
      },
      swiperOption2: {
        loop: false,
        speed: 1000,
        slidesPerView: 2,
        spaceBetween: 20,
        slidesPerGroup: 1,
        loopFillGroupWithBlank: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
      },
      fwtj: [
        /*  {
          name: "智能照明",
          url: require("@img/linshi/fwtj1.png"),
        }, */
      ],
    };
  },
  components: {
    vueSeamlessScroll,
    searchItem
  },
  computed: {
    ...mapState(["isLogin", "userInfo"]),
    menuActive() {
      return this.$route.path;
    },
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  mounted() {
    // 获取轮播图
    this.$http.banner_home().then((res) => {
      this.banners = res.data;
    });

    // 获取服务流程图
    this.$http.ad_home().then((res) => {
      this.lcimg = res.data.image;
    });
    //  获取施工案例
    this.$http.case_home().then((res) => {
      this.sgal = res.data.list;
    });
    // 获取服务推荐fwtjlist，sq
    this.$http.sq({}).then((res) => {
      this.fwtj = res.data;
    });
    this.$http.maintenance().then((res) => {
      this.listData = res.data;
    });
    this.$http.news_home().then((res) => {
      this.zxhd = res.data;
    });
  },
  methods: {
    ...mapMutations([
      "setLogin",
      "setUserInfo",
      "setLoginDialog",
      "setRegistDialog",
    ]),
    searchlist(val){
         this.$router.push({
          name:"qbfw",
          params: {
            from:"home",
            key:val
          },
        });
    },
    loginDialog() {
      this.setLoginDialog(true);
    },
    registDialog() {
      this.setRegistDialog(true);
    },
    openpage(path, id, islogin) {
      if (islogin) {
        if (this.isLogin != 1) {
           this.setLoginDialog(true);
          // this.$message.error("请先登录");
          return;
        }
      }
      if (id) {
        this.$router.push({
          path,
          query: {
            id,
          },
        });
      } else {
        this.$router.push({
          path,
        });
      }
    },
    jumpdetail(id, type) {
      // console.log(type)
// product news
        if(type=="product"){
          this.openpage("/fwdetail",id)
        }else{
        this.openpage("/zxdetail",id)
        }
    },
    getkefu() {
       if (this.isLogin != 1) {
           this.setLoginDialog(true);
      
        }else{
          this.$store.dispatch("setkefu");
        }
      
    },
    async getBanner() {
      try {
        const { data: res } = await this.$http.getBanner();

        if (res.code !== 200) {
          return this.$message.error("数据请求失败");
        }

        this.banners = res.banners;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/styles/mixin.scss";
.block {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.swiper-item {
  cursor: pointer;
}
.blockstyle {
  flex: 1;
  padding: 12px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  .toptitle {
    position: relative;
    width: 100%;
    padding-bottom: 12px;
    font-weight: bold;
    &:after {
      content: "";
      position: absolute;
      left: -12px;
      right: -12px;
      bottom: 0;
      height: 1px;
      background-color: $color-theme;
    }
    .more {
      float: right;
      font-size: 18px;
      display: flex;
      align-items: center;
      transition: all 1s linear;
      color: #1879F5;
      font-weight: normal;
      cursor: pointer;
      &:hover {
        color: $color-theme;
      }
      img {
        margin-left: 10px;
        margin-top: 2px;
      }
    }
    .icon {
      margin-right: 10px;
      transform: translateY(3px);
    }
  }
}
.fwlc {
  img {
    margin: 40px auto 30px;
    width: auto;
    display: block;
    max-height: 300px;
    max-width: 90%;
  }
}
.block1 {
  // height: 400px;
  // margin-top: 20px;
  .left {
    width: 905px;
    .swiper1 {
      height: 419px;
      border-radius: 6px;
      overflow: hidden;
    }
    .swiper-container img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .right {
    width: 275px;
    font-size: 16px;
    .up {
      height: 95px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      padding: 12px;
      border-radius: 6px;
      overflow: hidden;
      background-color: #fff;
      .welcome {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
      }
      .btns {
        margin-top: 18px;
      }
    }
    .down {
      padding: 12px;
      border-radius: 6px;
      margin-top: 20px;
      .main {
        margin-top: 16px;
        img {
          cursor: pointer;
          display: block;
          width: 100%;
          height: auto;
          margin-bottom: 12px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
.wxkb {
  font-size: 16px;
  height: 320px;
  overflow: hidden;
  .warp {
    display: flex;
    margin-bottom: 30px;

    .kehu {
      width: 25%;
    }
    .content {
      width: 55%;
      padding-left: 20px;
    }
    .date {
      width: 20%;
      color: #999;
      text-align: right;
    }
  }
}
.zxhd {
  .item {
    overflow: hidden;
    margin-bottom: 20px;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    .left {
      width: 200px;
      height: 140px;
      float: left;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .right {
      margin-left: 220px;
      height: 140px;
      display: flex;
      flex-direction: column;
      position: relative;
      // justify-content: space-between;
      .desc{
        color: 16px;
        line-height: 30px;
        color: #666;
        font-weight: 500;
        font-size: 16px;
        @include  multi-line-ellipsis(3)
      }
      .title {
        font-size: 18px;
        margin-bottom: 10px;
      }
      .bottom {
        font-size: 16px;
        color: #666;
        display: flex;
        justify-content: space-between;
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
      }
    }
  }
}
.fwtj {
  display: flex;
  // justify-content: space-between;
  .item {
    width: calc(25% - 11px);
    .pic {
      height: 165px;
      margin-bottom: 12px;
       border-radius: 5px;
          overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
      }
    }
    .txt {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 52px;
    }
    &:not(:last-child){
      margin-right: 15px;
    }
  }
}
.sgal {
  .img2 {
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    .before,
    .after {
      flex: 1;
      width: 190px;
      height: 230px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .tip {
        position: absolute;
        top: 0;
        left: 20px;
        font-size: 20px;
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
        padding: 10px 9px 15px;
        border-radius: 0 0 30px 30px;
        overflow: hidden;
        writing-mode: vertical-lr;
      }
    }
  }
  .zixun {
    margin-top: 20px;
  }
}

.main {
  margin-top: 20px;
}
.mr10 {
  margin-right: 10px;
}
.ml10 {
  margin-left: 10px;
}
.fwtj{
  cursor: pointer;
}
</style>
